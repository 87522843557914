import React, { useState } from 'react';
import Navbar from '../components/navbar'; // Import the Navbar component
import Footer from '../components/footer'; // Import the Footer component
import './teampage.css';

const TeamPage = () => {
    const [activeMember, setActiveMember] = useState(null);

    const toggleDetails = (memberName) => {
        setActiveMember((prev) => (prev === memberName ? null : memberName));
    };

    const members = [
        {
            name: 'Thomas Belyakov',
            role: '2D Artist',
            description: 'Thomas makes art for our games and for our company products.',
            picture: '/pictures/Thomas.png',
        },
        {
            name: 'Coleson Hannan',
            role: 'Infrastructure Engineer',
            description: 'I make sure that our infrastructure is functioning and cry when it doesn’t.\n I also made this site you\'re reading. O_<',
            picture: '/pictures/Coleson.png',
        },
        {
            name: 'Kyle Kobleur',
            role: '3D Artist',
            description: 'Kyle makes models and 3D assets for the team.',
            picture: '/pictures/Kyle_K.png',
        },
        {
            name: 'Nick LaMontagna',
            role: 'Co-Founder',
            description: 'Squashing bugs and making the next thing that will make the infrasctructure team scream.',
            picture: '/pictures/Nick.png',
        },
        {
            name: 'Brandon Melendez',
            role: 'Co-Founder',
            description: 'Brandon coordinates many of our administrative tasks along with marketing.',
            picture: '/pictures/Brandon.png',
        },
        {
            name: 'Alex Robinson',
            role: 'Infrastructure Engineer',
            description: 'My name is Alex. I do Infrastructure and some Dev work here at Tablebound!\nI love cooking and gaming in my free time.',
            picture: '/pictures/Alex.png',
        },
        {
            name: 'Victor Suarez',
            role: 'Security Engineer',
            description: 'Victor makes security horrors beyond the imagination.',
            picture: '/pictures/Victor.png',
        },
        {
            name: 'Kyle Verlangieri',
            role: 'Co-Founder',
            description: 'Loving cat dad. Building things by day and night.',
            picture: '/pictures/Kyle_V.png',
        },
    ];

    return (
        <div className="team-page">
            {/* Add Navbar at the top */}
            <Navbar />
            
            {/* Title at the top */}
            <h1 className="team-title">Oh, so you want to know?</h1>

            {/* Member list */}
            {members.map((member) => (
                <div className="member" key={member.name}>
                    <div
                        className="toggle-button"
                        onClick={() => toggleDetails(member.name)}
                    >
                        <div
                            className={`triangle ${
                                activeMember === member.name ? 'rotated' : ''
                            }`}
                        ></div>
                        <strong className="member-name">{member.name}</strong>
                    </div>
                    {activeMember === member.name && (
                        <div className="member-details">
                            <div className="member-info">
                                <div className="member-bio">
                                    <h4 className="member-role">{member.role}</h4>
                                    <p className="member-description">
                                        {member.description.split('\n').map((line, index) => (
                                            <React.Fragment key={index}>
                                                {line}
                                                <br />
                                            </React.Fragment>
                                        ))}
                                    </p>
                                </div>
                                <img
                                    src={member.picture}
                                    alt={member.name}
                                    className="member-picture"
                                />
                            </div>
                        </div>
                    )}
                </div>
            ))}

            {/* Footer at the bottom */}
            <Footer />
        </div>
    );
};

export default TeamPage;
