import React from "react";
import "./style/ourproducts.css"; // You can create a CSS file for styling
import kerfluffle from "../kerfluffle.png"; // Import local image
import meepl from "../meepl.png"
import overrealms from "../Overralms.png"

const products = [
  {
    id: 1,
    title: "Kerfluffle",
    subtitle: "A colorfully chaotic party game",
    image: kerfluffle, // Replace with your image URL
    link: "https://www.youtube.com/watch?v=dQw4w9WgXcQ", // External domain
    isNew: true,
  },
  {
    id: 2,
    title: "Meepl",
    subtitle: "Coming soon",
    image: meepl, // Replace with your image URL
    link: "https://meepl.net",
  },
  {
    id: 3,
    title: "Overrealms",
    subtitle: "Watch your coffee table become the arena with this immersive card game",
    image: overrealms, // Replace with your image URL
    link: "https://overrealms.com",
  },
];

function OurProducts() {
  return (
    <div className="products-container">
      <h1 className="products-heading">Our Wonderful Products</h1>
      <div className="products-cards">
        {products.map((product) => (
          <a
            href={product.link}
            key={product.id}
            target="_blank"
            rel="noopener noreferrer"
            className="product-link"
          >
            <div
              className={`product-card ${product.isNew ? "large-card" : "small-card"}`}
            >
              <img src={product.image} alt={product.title} className="product-image" />
              <div className="product-text">
                <h2 className="product-title">{product.title}</h2>
                <p className="product-subtitle">{product.subtitle}</p>
              </div>
            </div>
          </a>
        ))}
      </div>
    </div>
  );
}

export default OurProducts;
