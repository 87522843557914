import React from 'react';
import { Link, useLocation } from 'react-router-dom'; // Import Link and useLocation

const Navbar = ({ onScrollToAboutUs, onScrollToProducts, onScrollToContactUs }) => {
    const location = useLocation(); // Get current location to check if on home page

    return (
        <>
            <link 
                href="https://fonts.googleapis.com/css2?family=Barlow:wght@400;700&display=swap" 
                rel="stylesheet"
            />
            <nav style={styles.navbar}>
                <div style={styles.navbarContainer}>
                    <div style={styles.leftNavItem}>
                        <Link to="/">
                            <img src="/icons/logo.svg" alt="Logo" style={styles.svgIcon} />
                        </Link>
                    </div>
                    <div style={styles.rightNavItems}>
                        {/* About Us Button */}
                        {location.pathname === '/' ? (
                            <button onClick={onScrollToAboutUs} style={styles.navItem}>About Us</button>
                        ) : (
                            <Link to="/" style={styles.navItem}>About Us</Link>
                        )}

                        {/* Our Products Button */}
                        {location.pathname === '/' ? (
                            <button onClick={onScrollToProducts} style={styles.navItem}>Our Products</button>
                        ) : (
                            <Link to="/" style={styles.navItem}>Our Products</Link>
                        )}

                        {/* Our Team Button: Always links to /team */}
                        <Link to="/team" style={styles.navItem}>Our Team</Link>

                        {/* Contact Us Button */}
                        {location.pathname === '/' ? (
                            <button onClick={onScrollToContactUs} style={styles.contactUsButton}>Contact Us</button>
                        ) : (
                            <Link to="/" style={styles.contactUsButton}>Contact Us</Link>
                        )}
                    </div>
                </div>
            </nav>
        </>
    );
};

const styles = {
    navbar: {
        color: '#e8e5ea',
        padding: '5px 20px',
        position: 'fixed',
        top: 0,
        width: '100%',
        zIndex: 1000,
        fontFamily: 'Barlow, sans-serif',
        fontWeight: '700',
    },
    navbarContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    leftNavItem: {
        display: 'flex',
        alignItems: 'center',
    },
    rightNavItems: {
        display: 'flex',
        gap: '10px', // Adjust gap for spacing between items
    },
    navItem: {
        background: 'none',
        border: 'none',
        color: '#e8e5ea',
        textDecoration: 'none',
        padding: '0px 20px', // Adjust padding for consistent spacing
        fontSize: '16px',
        cursor: 'pointer',
        transition: 'color 0.3s ease',
        fontFamily: 'Barlow, sans-serif',
        fontWeight: '700', // Make all items bold
    },
    // Specific style for the "Contact Us" button
    contactUsButton: {
        background: 'none',
        border: 'none',
        color: '#e8e5ea',
        textDecoration: 'none',
        padding: '0px 20px', // Consistent padding
        fontSize: '16px',
        cursor: 'pointer',
        transition: 'color 0.3s ease',
        fontFamily: 'Barlow, sans-serif',
        fontWeight: '700', // Ensure boldness here too
        marginRight: '40px', // Add margin to the right to give more space
    },
    svgIcon: {
        color: '#e8e5ea',
        width: '48px',
        height: '48px',
        cursor: 'pointer',
        transition: 'color 0.3s ease',
    },
};

export default Navbar;
