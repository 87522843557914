import React from 'react';
import './style/header.css';

const Header = () => {
  return (
    <div className="header">
      <video autoPlay muted loop className="background-video">
        <source src="https://www.w3schools.com/howto/rain.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="overlay-text">
        <p><span style={{ color: '#b75fff' }}>Hey!</span> We make social immersive experiences.</p>
        <p>Check us out on our socials to see what we are up to.</p>
      </div>
    </div>
  );
};

export default Header;
