import React, { useRef } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import './fonts.css';
import Navbar from './components/navbar';
import Header from './components/header';
import AboutUs from './components/aboutus';
import OurProducts from './components/ourproducts';
import ContactUs from './components/contactus';
import Footer from './components/footer';
import TeamPage from './pages/teampage'; // Import the new TeamPage component
import TermsOfService from './pages/termsofservice'; // Import Terms of Service page
import PrivacyPolicy from './pages/privacypolicy'; // Import Privacy Policy page

function App() {
    const aboutUsRef = useRef(null);
    const productsRef = useRef(null);
    const contactUsRef = useRef(null);

    const scrollToSection = (sectionRef) => {
        sectionRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    const HomePage = () => (
        <div>
            <Navbar
                onScrollToAboutUs={() => scrollToSection(aboutUsRef)}
                onScrollToProducts={() => scrollToSection(productsRef)}
                onScrollToContactUs={() => scrollToSection(contactUsRef)}
            />
            <Header />
            <div ref={aboutUsRef}>
                <AboutUs />
            </div>
            <div ref={productsRef}>
                <OurProducts />
            </div>
            <div ref={contactUsRef}>
                <ContactUs />
            </div>
            <Footer />
        </div>
    );

    return (
        <Router>
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/team" element={<TeamPage />} />
                <Route path="/terms" element={<TermsOfService />} /> {/* Terms of Service route */}
                <Route path="/privacy" element={<PrivacyPolicy />} /> {/* Privacy Policy route */}
            </Routes>
        </Router>
    );
}

export default App;
