import React from 'react';
import Navbar from '../components/navbar';
import Footer from '../components/footer';  // Import the Footer component

const TermsOfService = () => {
    return (
        <div style={{ backgroundColor: '#0c080f', minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
            <Navbar />
            <div style={{ color: '#e8e5ea', padding: '20px', flex: '1' }}>
                <h1
                    style={{
                        fontFamily: 'Barlow, sans-serif',
                        fontWeight: '700',
                        fontSize: '36px',
                        textAlign: 'center',
                        marginTop: '80px',  // Add spacing from the navbar
                    }}
                >
                    Terms of Service
                </h1>
                <div
                    style={{
                        textAlign: 'center',  // Center the content text
                        maxWidth: '800px',  // Limit the width of the text for readability
                        margin: '0 auto',  // Center the content in the page
                        fontSize: '18px',  // Adjust font size
                        lineHeight: '1.6',  // Improve line spacing
                        padding: '0 20px',  // Add some padding to the sides for better readability
                    }}
                >
                    <p>Coming Soon</p>
                    <p></p>
                    <p></p>
                </div>
            </div>
            <Footer />  {/* Footer component added here */}
        </div>
    );
};

export default TermsOfService;
